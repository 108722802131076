import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { createGlobalState } from "react-use";

import "normalize.css";
import "../styles/base.css";
import "../styles/fonts.css";
import "mapbox-gl/dist/mapbox-gl.css";

import Header from "../components/Header";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import SubFooter from "../components/SubFooter";
import FooterBar from "../components/FooterBar";
import HeaderElements from "../components/HeaderElements";
import Lightbox from "../components/Lightbox";

const LayoutDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .shadow {
    // padding: 20px;
    box-shadow: 5px 4px 16px -9px rgba(0, 0, 0, 0.53);
    -webkit-box-shadow: 5px 4px 16px -9px rgba(0, 0, 0, 0.53);
    -moz-box-shadow: 5px 4px 16px -9px rgba(0, 0, 0, 0.53);
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

export const lightboxOpenGlobal = createGlobalState(false);
export const lightboxContentGlobal = createGlobalState([]);
export const lightboxIndexGlobal = createGlobalState(0);
//this state updates too late, lb is opened before state is updated

const DefaultLayout: React.FC = ({
  children,
  siteId,
  title,
  //links,
  green,
  beschreibung,
}) => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        entries(type: "metaDatenLokalisierung", siteId: [1, 2, 3, 4]) {
          siteId
          ... on CMS_main_metaDatenLokalisierung_Entry {
            metaBeschreibung
          }
        }
      }
    }
  `);
  const findDescription = (entries) => {
    if (siteId) {
      const entry = entries.find((obj) => obj.siteId == siteId);
      return entry.metaBeschreibung;
    }
    const entry = entries.find((obj) => obj.siteId == 2);
    return entry.metaBeschreibung;
  };
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [description, setDescription] = useState(
    findDescription(data.cms.entries)
  );

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "UA-218898444-1");
  });

  return (
    <LayoutDiv>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="msapplication-config"
          content="/assets/favicon/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff"></meta>
        <title>{title ? title + " – Swiss Block®" : "Swiss Block®"}</title>
        <meta
          name="description"
          content={beschreibung ? beschreibung : description}
        ></meta>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-218898444-1"
        ></script>
      </Helmet>

      <Header />
      <Navigation
        siteId={siteId}
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
      />
      <HeaderElements
        siteId={siteId}
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
      />
      <main className="content">{children}</main>
      <Lightbox />
      <FooterBar />
      <Footer green={green} />
      <SubFooter />
    </LayoutDiv>
  );
};

export default DefaultLayout;
