import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const LogoDiv = styled.div`
  width: 25vw;
  height: 12.5vw;
  z-index: 101;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  max-height: 240px;
  position: relative;
  top: 13.3vw;
  left: 0;

  > a {
    display: block;
  }

  img {
    display: block;
    width: 24vw;
    max-width: 461.46px;
    max-height: 240px;
  }
`;

const Logo = () => (
  <LogoDiv>
    <Link to="/">
      <img
        src="/assets/logo/swissblock-logo-nav.svg"
        alt="Swiss Block® Logo, Link zu Startseite"
      />
    </Link>
  </LogoDiv>
);

export default Logo;
