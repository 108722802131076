//import function as useEffect function

//this should work with the content from dangerouslySetInnerHTML
export const superScriptRHelperHTML = (text) => {
  if (typeof text === "string") {
    return text.replace(/\®/g, "<sup>®</sup>");
  } else {
    return text;
  }
};

//how to do this with {} text
