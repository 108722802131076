import React from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";

const FooterRightDiv = styled.div`
  background: black;
  padding: 30px 12px 11px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  a {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    p {
      color: #fff;
      font: 14px / 18px "Oswald Bold";
    }
  }

  > a {
    margin-bottom: 26px;
  }

  img {
    height: 24.37px;
    margin-right: 7px;
    margin-top: -4px;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 3.37vw 4.53vw 20px;
    height: 12.5vw;
    max-height: 240px;

    > a {
      position: relative;
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    padding: 3.37vw 4.53vw 24px;

    a p {
      font: 20px / 28px "Oswald Bold";
    }

    img {
      height: 30px;
      margin-right: 10px;
      margin-top: -1px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 0 60px 30px;

    a p {
      font: 24px / 32px "Oswald Bold";
    }

    > a {
      margin-bottom: 45px;
    }

    img {
      height: 32px;
      margin-right: 11px;
      margin-top: 0;
    }
  }
`;

interface FooterRightProps {
  inhalt: string;
}

interface FooterRightStaticQueryProps {
  cms: {
    entry: {
      uri: string;
    };
  };
}

const FooterRight: React.FC<FooterRightProps> = ({ inhalt }) => {
  const data = useStaticQuery<FooterRightStaticQueryProps>(graphql`
    query {
      cms {
        entry(type: "hersteller") {
          uri
        }
      }
    }
  `);
  return (
    <FooterRightDiv>
      <Link to={"/" + data.cms.entry.uri}>
        <div>
          <img
            src="/assets/gestaltungselemente/location-green-new.svg"
            alt="map pin"
          />
        </div>
        <div>
          <p>{inhalt}</p>
        </div>
      </Link>
    </FooterRightDiv>
  );
};

export default FooterRight;
