import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";
import { superScriptRHelperHTML } from "../../data/superscriptHelper";

interface NavLinkProps {
  entry: {
    uri: string;
    title: string;
  };
}

const LinkElem = styled(Link)`
  color: white;
  z-index: 110;
  text-decoration: none;
  display: block;
  text-align: center;
  font: 18px / 70px "Oswald Regular";
  max-width: 500px;
  margin: 0 auto;

  &:hover,
  &.active {
    color: ${colors.green};
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    font: 28px / 80px "Oswald Regular";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 32px / 90px "Oswald Regular";
  }
`;

const NavLink: React.FC<NavLinkProps> = ({ entry, setNavIsOpen }) => (
  <LinkElem
    to={"/" + entry.uri}
    activeClassName="active"
    dangerouslySetInnerHTML={{ __html: superScriptRHelperHTML(entry.title) }}
    onClick={() => setNavIsOpen(false)}
  />
);

export default NavLink;
