export const systemsteinItem = {
  de: {
    lange: "Länge",
    breite: "Breite",
    hohe: "Höhe",
    gewicht: "Gewicht",
    artikelNummer: "Art. Nr. ",
  },
  en: {
    lange: "Length",
    breite: "Width",
    hohe: "Height",
    gewicht: "Weight",
    artikelNummer: "Art. Nr. ",
  },
};

export const Switzerland = {
  name: "Schweiz",
  latitude: 46.8773697,
  longitude: 8.3966319,
  zoom: 7,
  filterTitle: "Switzerland",
};

export const Austria = {
  name: "Österreich",
  latitude: 47.6042775,
  longitude: 13.8272152,
  zoom: 6,
  filterTitle: "",
};

export const Germany = {
  name: "Deutschland",
  latitude: 50.9214172,
  longitude: 10.1994669,
  zoom: 4.7,
  filterTitle: "",
};

export const USA = {
  name: "USA",
  latitude: 39.6370018,
  longitude: -103.1194025,
  zoom: 3.3,
  filterTitle: "",
};

export const Italy = {
  name: "Italien",
  latitude: 43.1149416,
  longitude: 12.1886368,
  zoom: 4.7,
  filterTitle: "",
};

export const imgix = {
  gridImg: {
    auto: "format,compress",
    fit: "crop",
    crop: "entropy",
    q: 20,
    w: 1000,
    h: 1000,
  },
  gridLogoImg: {
    auto: "format,compress",
    fit: "clip",
    q: 20,
    w: 1000,
  },
  Img: {
    auto: "format,compress",
    fit: "clip",
    q: 20,
    w: 600,
  },
  fullImg: {
    auto: "format,compress",
    fit: "crop",
    crop: "entropy",
    q: 20,
  },
  maxWidthImage: {
    auto: "format,compress",
    q: 20,
    w: 2560,
  },
};

export const KantonLokalisierung = {
  kanton: {
    de: "Kanton",
    en: "Canton",
    fr: "Canton",
    it: "Canton",
  },
  bundesland: {
    de: "Bundesland",
    en: "State",
    fr: "État",
    it: "Stato",
  },
  state: {
    de: "Bundestaat",
    en: "State",
    fr: "État",
    it: "Stato",
  },
  provinz: {
    de: "Provinz",
    en: "Province",
    fr: "Province",
    it: "Provincia",
  },
};

export const getStateLoc = (siteId, country) => {
  if (siteId === 1) {
    if (country.name === "Schweiz") {
      return KantonLokalisierung.kanton.de;
    } else if (
      country.name === "Österreich" ||
      country.name === "Deutschland"
    ) {
      return KantonLokalisierung.bundesland.de;
    } else if (country.name === "USA") {
      return KantonLokalisierung.state.de;
    } else {
      return KantonLokalisierung.provinz.de;
    }
  } else if (siteId === 2) {
    if (country.name === "Schweiz") {
      return KantonLokalisierung.kanton.en;
    } else if (
      country.name === "Österreich" ||
      country.name === "Deutschland"
    ) {
      return KantonLokalisierung.bundesland.en;
    } else if (country.name === "USA") {
      return KantonLokalisierung.state.en;
    } else {
      return KantonLokalisierung.provinz.en;
    }
  } else if (siteId === 3) {
    if (country.name === "Schweiz") {
      return KantonLokalisierung.kanton.fr;
    } else if (
      country.name === "Österreich" ||
      country.name === "Deutschland"
    ) {
      return KantonLokalisierung.bundesland.fr;
    } else if (country.name === "USA") {
      return KantonLokalisierung.state.fr;
    } else {
      return KantonLokalisierung.provinz.fr;
    }
  } else {
    if (country.name === "Schweiz") {
      return KantonLokalisierung.kanton.it;
    } else if (
      country.name === "Österreich" ||
      country.name === "Deutschland"
    ) {
      return KantonLokalisierung.bundesland.it;
    } else if (country.name === "USA") {
      return KantonLokalisierung.state.it;
    } else {
      return KantonLokalisierung.provinz.it;
    }
  }
};
