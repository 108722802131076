import React from "react";
import styled from "styled-components";
import { superScriptRHelperHTML } from "../data/superscriptHelper";
import { breakpoints, colors } from "../styles/variables";
import CenteredFourSixthsGrid from "./Layout/Grids/CenteredFourSixthsGrid";
import Wrapper from "./Layout/Wrapper";

const GreenLeadDiv = styled.div`
  text-align: center;
  background: ${colors.green};
  margin-top: -1px;
  h2 {
    color: white;
    margin-bottom: 6px;
  }

  p {
    font: 16px / 24px "Oswald Light";
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    h2 {
      margin-bottom: 14px;
    }
    p {
      font: 21px / 31px "Oswald Light";
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    p {
      font: 24px / 36px "Oswald Light";
    }
  }
`;

const GreenLead = ({ inhalt }) => (
  <GreenLeadDiv>
    <Wrapper>
      <CenteredFourSixthsGrid>
        <div
          dangerouslySetInnerHTML={{ __html: superScriptRHelperHTML(inhalt) }}
        ></div>
      </CenteredFourSixthsGrid>
    </Wrapper>
  </GreenLeadDiv>
);

export default GreenLead;
