import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";

import { breakpoints, colors } from "../../styles/variables";

interface ImpressumLinksProps {
  seperate: boolean;
}

interface ImpressumLinksStaticQueryProps {
  cms: {
    entries: [
      {
        title: string;
        uri: string;
      }
    ];
  };
}

const LinksDiv = styled.div`
  display: none;
  text-align: center;

  a {
    font: 11px / 21px "Oswald Regular";

    &:hover {
      color: ${colors.green};
    }

    &:not(:last-of-type) {
      &:after {
        content: "   |   ";
      }
    }
  }

  &.seperate {
    display: block;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: block;
    &.seperate {
      display: none;
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    a {
      font: 13px / 18px "Oswald Light";
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    a {
      font: 14px / 21px "Oswald Light";
    }
  }
`;

const ImpressumLinks: React.FC<ImpressumLinksProps> = ({ seperate }) => {
  const data = useStaticQuery<ImpressumLinksStaticQueryProps>(graphql`
    query {
      cms {
        entries(type: "textSeite", level: 2) {
          title
          uri
        }
      }
    }
  `);
  return (
    <LinksDiv className={seperate ? "seperate" : ""}>
      {data.cms.entries.map((entry, index) => (
        <Link to={"/" + entry.uri} key={index}>
          {entry.title}
        </Link>
      ))}
    </LinksDiv>
  );
};

export default ImpressumLinks;
