import React from "react";
import styled from "styled-components";
import { superScriptRHelperHTML } from "../data/superscriptHelper";
import { breakpoints, colors } from "../styles/variables";

interface HeaderTitleProps {
  inhalt: string;
}

const HeaderTitleDiv = styled.div`
  background: ${colors.green};
  text-align: center;
  display: flex;
  justify-content: center;
  word-break: break-word;
  min-height: calc(0.52 * 100vw * 0.24);

  h1 {
    margin-top: 2.97vw;
    text-transform: uppercase;
    letter-spacing: 2px;
    max-width: 25vw;
    color: #fff;
    hyphens: auto;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    h1 {
      margin-top: 2.97vw;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    max-height: 240px;

    h1 {
      margin-top: 75px;
      max-width: 480px;
    }
  }
`;

const HeaderTitle: React.FC<HeaderTitleProps> = ({ inhalt }) => (
  <HeaderTitleDiv>
    <h1
      dangerouslySetInnerHTML={{ __html: superScriptRHelperHTML(inhalt) }}
    ></h1>
  </HeaderTitleDiv>
);

export default HeaderTitle;
