import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Navigation, Pagination, Keyboard, a11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  lightboxContentGlobal,
  lightboxOpenGlobal,
  lightboxIndexGlobal,
} from "../layouts/default";
import Imgix from "react-imgix";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/keyboard";
import { breakpoints, colors } from "../styles/variables";
import YouTubeEmbed from "./Lightbox/YouTubeEmbed";
import Close from "./Lightbox/Close";
import { imgix } from "../data/data";
import Model3DLightbox from "./Model3DLightbox";
// import Model3D from "./Model3DLightbox";

const LightboxDiv = styled.div`
  /* .innerLightbox {
    margin: auto 0;
  } */
  display: none;
  .swiper-button-next,
  .swiper-button-prev,
  .swiper-pagination-bullet-active {
    color: ${colors.green};
  }

  .swiper-pagination-bullet-active {
    background: ${colors.green};
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 3px;
  }

  > div > .swiper {
    width: 90vw;
    max-width: 90vh;
    height: 90vh;
    max-height: 90vw;
    margin: 0 auto;
    padding: 5%;
    background: white;
    position: relative;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 85%;
      margin: 0 auto;
    }
  }

  &.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    .swiper-slide {
      img {
        max-height: 90%;
      }
    }
  }
`;

const Lightbox = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = lightboxOpenGlobal();
  const [lightboxContent, setLightboxContent] = lightboxContentGlobal();
  const [lightboxIndex, setLightboxIndex] = lightboxIndexGlobal();

  const escapePress = (event) => {
    if (event.key === "Escape" && lightboxIsOpen) {
      setLightboxIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escapePress);

    if (!lightboxIsOpen) {
      setLightboxContent([]);
      setLightboxIndex(0);
    }
  }, [lightboxIsOpen]);

  const LightboxContent = (item, index) => {
    if (item.videoUrl) {
      return (
        <SwiperSlide key={index}>
          <YouTubeEmbed url={item.videoUrl} />
        </SwiperSlide>
      );
    } else if (item.bild && item.bild.length > 0) {
      return (
        <SwiperSlide key={index}>
          <Imgix
            src={item.bild[0].url}
            imgixParams={imgix.fullImg}
            htmlAttribute={{ alt: item.bild[0].title }}
          />
        </SwiperSlide>
      );
    } else if (item.modell && item.modell.length > 0) {
      // never worked.
      return null;
      return (
        <SwiperSlide key={index}>
          <Model3DLightbox
            url={"/assets/models/"+item.modell[0].filename}
          />
        </SwiperSlide>
      );
    } else {
      return "";
    }
  };

  if (lightboxIsOpen) {
    return (
      <LightboxDiv
        className={lightboxIsOpen ? "open" : ""}
        onKeyDown={(event) => escapePress(event)}
      >
        <div className="innerLightbox">
          <Swiper
            modules={[Navigation, Pagination, Keyboard]}
            navigation
            keyboard={{ enabled: true, onlyInViewport: true }}
            a11y={{
              prevSlideMessage: "Previous slide",
              nextSlideMessage: "Next slide",
            }}
            pagination={{ clickable: true }}
            initialSlide={lightboxIndex}
            spaceBetween={300}
          >
            <Close />
            {lightboxContent.map((item, index) => {
              return LightboxContent(item, index);
            })}
          </Swiper>
        </div>
      </LightboxDiv>
    );
  } else {
    return "";
  }
};

export default Lightbox;
