import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import { lightboxOpenGlobal } from "../../layouts/default";

const CloseDiv = styled.div`
  width: 44px;
  height: 44px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 303;

  div {
    width: 44px;
    height: 3px;
    background: ${colors.green};
    border-radius: 3px;
    position: absolute;
    top: 20.5px;

    &:first-of-type {
      transform: rotate(45deg);
    }
    &:last-of-type {
      transform: rotate(-45deg);
    }
  }
`;

//27 * 44
const Close = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = lightboxOpenGlobal();

  return (
    <CloseDiv onClick={() => setLightboxIsOpen(false)} title="close lightbox">
      <div></div>
      <div></div>
    </CloseDiv>
  );
};

export default Close;
