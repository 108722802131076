import React from "react";
import styled from "styled-components";
import { superScriptRHelperHTML } from "../../data/superscriptHelper";
import { breakpoints } from "../../styles/variables";

const FooterLeftDiv = styled.div`
  background: black;
  min-height: 91px;
  padding: 25px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > address {
    position: absolute;
    left: 4.53vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    font: 12px / 17px "Oswald Light";
    a {
      display: block;
      color: white;

      &.mapsLink {
        display: inline;
      }
    }
    > div {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    width: 25vw;
    padding: 3.37vw 0 20px;
    height: 12.5vw;
    max-height: 240px;

    > address {
      grid-column-gap: 0;
      font: 12px / 18px "Oswald Light";
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    padding: 3.37vw 0 24px;

    > address {
      font: 16px / 24px "Oswald Light";
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 3.37vw 0 30px;

    > address {
      left: 60px;
      font: 18px / 27px "Oswald Light";
    }
  }
`;

interface FooterLeftProps {
  inhalt: {
    seitenTitel: string;
    strasseHausnummer: string;
    postleitzahl: string;
    ort: string;
    telefon: string;
    websiteUrl: string;
    mapsLink: string;
  };
}

const FooterLeft: React.FC<FooterLeftProps> = ({ inhalt }) => (
  <FooterLeftDiv>
    <address>
      <div>
        <strong
          dangerouslySetInnerHTML={{
            __html: superScriptRHelperHTML(inhalt.seitenTitel),
          }}
        />
        <a className="mapsLink" href={inhalt.mapsLink}>
          {inhalt.strasseHausnummer} <br />
          CH-{inhalt.postleitzahl} {inhalt.ort}
        </a>
      </div>
      <div>
        <a href="mailto:info@swissblock.com">info@swissblock.com</a>
        <a href={`tel:${inhalt.telefon}`}>{inhalt.telefon}</a>
      </div>
    </address>
  </FooterLeftDiv>
);

export default FooterLeft;
