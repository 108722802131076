import React from "react";
import styled from "styled-components";
import NavLinkList from "./NavLinkList";

interface NavContentProps {
  siteId: number;
}

const ContentDiv = styled.div`
  margin: 0 auto;
  height: 100vh;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 103;

  > div {
    //width: 25vw;
    //height: 100vh;
    margin: 0 auto;
  }
`;

const NavContent: React.FC<NavContentProps> = ({ siteId, setNavIsOpen }) => (
  <ContentDiv>
    <div>
      <NavLinkList siteId={siteId} setNavIsOpen={setNavIsOpen} />
    </div>
  </ContentDiv>
);

export default NavContent;
