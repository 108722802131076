import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import NavLink from "./NavLink";
import NavLinkLokalerHersteller from "./NavLinkLokalerHersteller";

interface NavLinksQueryProps {
  cms: {
    links: [
      {
        title: string;
        uri: string;
        siteId: number;
      }
    ];
    hersteller: {
      uri: string;
    };
    lokalisierung: {
      herstellerFinden: string;
    };
  };
}

interface NavLinkListProps {
  siteId?: number;
}

const LinkList = styled.div`
  z-index: 107;
  margin-top: 12.5vw;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    margin-top: 5vw;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-top: 3vw;
  }
`;

const NavLinkList: React.FC<NavLinkListProps> = ({ siteId, setNavIsOpen }) => {
  const data = useStaticQuery<NavLinksQueryProps>(graphql`
    query {
      cms {
        links: entries(level: 1, siteId: [1, 2, 3, 4]) {
          title
          uri
          siteId
        }
      }
    }
  `);

  const filteredLinks = data.cms.links.filter(
    (entry) => entry.siteId === siteId
  );

  return (
    <LinkList>
      {siteId ? (
        filteredLinks.map((entry, index) => {
          return index + 1 === filteredLinks.length ? (
            <NavLinkLokalerHersteller
              text={entry.title}
              link={entry.uri}
              key={index}
              setNavIsOpen={setNavIsOpen}
            />
          ) : (
            <NavLink entry={entry} key={index} setNavIsOpen={setNavIsOpen} />
          );
        })
      ) : (
        <NavLink entry={{ title: "Swiss Block®", uri: "" }} />
      )}
    </LinkList>
  );
};

export default NavLinkList;
