import React from "react";
import styled from "styled-components";

const EmbedDiv = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: row;

  div {
    width: 100vw;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0%;
      left: 0%;
      right: 0%;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }
`;

const YouTubeEmbed = ({ url }) => {
    if(!url || !url.split(".com/watch?v=").length){return null}

  return(
  <EmbedDiv>
    <div>
      <iframe
        src={
          "https://www.youtube.com/embed/" +
          url.split(".com/watch?v=")[1] +
          "?modestbranding=1&playsinline=1&rel=0"
        }
        frameborder="0"
        allowfullscreen
        title=""
      ></iframe>
    </div>
  </EmbedDiv>
)};

export default YouTubeEmbed;
