import React from "react";
import styled from "styled-components";
import NavContent from "./Navigation/NavContent";
import Trapezoid from "./Navigation/Trapezoid";

interface NavigationProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  siteId: number;
}

const NavDiv = styled.nav`
  z-index: 103;
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: -150vh;
  transition: bottom 600ms ease-out;
  width: 100vw;
  height: 100vh;

  &.navIsOpen {
    bottom: 0;
  }

  > div:nth-of-type(2) {
    background: #000;
    width: 100%;
    flex-grow: 1;
  }
`;

const Navigation: React.FC<NavigationProps> = ({
  navIsOpen,
  siteId,
  setNavIsOpen,
}) => (
  <NavDiv className={navIsOpen ? "navIsOpen" : ""}>
    <Trapezoid />
    <div>
      <NavContent siteId={siteId} setNavIsOpen={setNavIsOpen} />
    </div>
  </NavDiv>
);

export default Navigation;
