import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import Logo from "./Header/Logo";
import NavControl from "./Header/NavControl";

interface HeaderElements {
  siteId: number;
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  links?: [
    {
      uri: string;
      siteId: number;
    }
  ];
}

const HeaderElementsElement = styled.header`
  margin: 0 auto;
  width: 100vw;
  max-width: 1920px;
  position: fixed;
  top: calc(0.52 * 100vw * -0.26);
  left: 0;
  right: 0;
  z-index: 120;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    > div {
      display: block;
    }
  }
`;

const HeaderElements: React.FC<HeaderElements> = ({
  siteId,
  navIsOpen,
  setNavIsOpen,
  //links,
}) => {
  return (
    <HeaderElementsElement>
      <Logo />
      <NavControl
        siteId={siteId}
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
        //links={links}
      />
    </HeaderElementsElement>
  );
};

export default HeaderElements;
