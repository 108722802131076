import React from "react";
import styled from "styled-components";

const TrapezoidDiv = styled.div`
  height: 12.5vw;
  width: 50vw;
  margin: 0 auto;
  margin-bottom: -1px;
  max-width: 960px;
  max-height: 240px;
  background: url("/assets/gestaltungselemente/footer-mobile-and-nav-trapez.svg");
  background-size: 100%;
  z-index: 103;
`;

const Trapezoid = () => <TrapezoidDiv></TrapezoidDiv>;

export default Trapezoid;
