import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

interface HeaderProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  siteId: number;
  links: [{ uri: string; siteId: number }];
}

const OuterHeaderLeft = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  margin-left: -100vw;
  max-width: 6474px;
  z-index: 98;
  left: 0;
  margin: 0 auto;
`;

const OuterHeaderRight = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  margin-left: -100vw;
  max-width: 6474px;
  z-index: 98;
  right: 0;
  margin: 0 auto;
`;

const HeaderDivLeft = styled.div`
  width: 37.5vw;
  height: calc(0.52 * 100vw * 0.26);
  max-height: 259px;
  background-image: url("/assets/gestaltungselemente/header-left.svg");
  background-position: right 0px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  top: 0;
  left: 0;
  overflow-y: visible;
  z-index: 99;

  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      background-image: url("/assets/gestaltungselemente/header-left-no-shadow.svg");
    }
  }
  @media screen and (min-width: ${breakpoints.desktopXL}px) {
    width: calc(720px + ((100vw - 1920px) / 2));
  }
`;

const HeaderDivRight = styled.div`
  width: 37.5vw;
  height: calc(0.52 * 100vw * 0.26);
  max-height: 259px;
  background-image: url("/assets/gestaltungselemente/header-right.svg");
  background-position: left 0px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  top: 0;
  right: 0;
  overflow-y: visible;
  z-index: 99;

  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      background-image: url("/assets/gestaltungselemente/header-right-no-shadow.svg");
    }
  }

  @media screen and (min-width: ${breakpoints.desktopXL}px) {
    width: calc(720px + ((100vw - 1920px) / 2));
  }
`;

const Header: React.FC<HeaderProps> = () => {
  return (
    <>
      <OuterHeaderLeft>
        <HeaderDivLeft />
      </OuterHeaderLeft>
      <OuterHeaderRight>
        <HeaderDivRight />
      </OuterHeaderRight>
    </>
  );
};

export default Header;
