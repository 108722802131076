import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const Bar = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    display: block;
    height: 7.5vw;
    max-height: 75px;
    width: 100vw;
    background: #000;
  }
`;

const FooterBar = () => <Bar />;

export default FooterBar;
