import React from "react";
import styled from "styled-components";

import { breakpoints } from "../../styles/variables";

interface NavToggleProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleDiv = styled.div`
  width: 25px;
  height: 16px;
  position: relative;
  cursor: pointer;
  z-index: 121;

  > div {
    width: 25px;
    height: 2px;
    position: absolute;
    background: #000;
    transition: transform 0.2s ease 0s, top 0.3s ease-in-out 0.3s;
  }

  > div:nth-of-type(1) {
    top: 0px;
  }
  > div:nth-of-type(2) {
    transition: all 300ms;
    top: 7px;
  }
  > div:nth-of-type(3) {
    top: 14px;
  }

  &.navIsOpen {
    > div {
      top: 7px;
    }
    > div:nth-of-type(2) {
      transition: all 300ms;
      background: rgba(255, 255, 255, 0);
    }
    > div:nth-of-type(1) {
      transform: rotate(45deg);
    }

    > div:nth-of-type(3) {
      transform: rotate(-45deg);
    }

    > div {
      transition: top 0.2s ease 0s, transform 0.3s ease-in-out 0.3s;
    }
  }
  @media screen AND (min-width: ${breakpoints.tablet}px) {
    width: 40px;
    height: 32px;

    > div {
      width: 40px;
      height: 3px;
    }

    > div:nth-of-type(1) {
      top: 0px;
    }
    > div:nth-of-type(2) {
      top: 14px;
    }
    > div:nth-of-type(3) {
      top: 28px;
    }

    &.navIsOpen {
      > div {
        top: 14px;
      }
    }
  }
  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    width: 45px;
    height: 36px;

    > div {
      width: 45px;
      height: 4px;
    }

    > div:nth-of-type(1) {
      top: 0px;
    }
    > div:nth-of-type(2) {
      top: 16px;
    }
    > div:nth-of-type(3) {
      top: 32px;
    }

    &.navIsOpen {
      > div {
        top: 16px;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-right: 14px;
  }
`;

const NavToggle: React.FC<NavToggleProps> = ({ navIsOpen, setNavIsOpen }) => (
  <ToggleDiv
    onClick={() => setNavIsOpen(!navIsOpen)}
    className={navIsOpen ? "navIsOpen" : ""}
    title="open/close navigation"
  >
    <div></div>
    <div></div>
    <div></div>
  </ToggleDiv>
);

export default NavToggle;
