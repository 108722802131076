import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import ImpressumLinks from "./Footer/ImpressumLinks";

const SubFooterDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 10px 10px;
  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const SubFooter = () => (
  <SubFooterDiv>
    <ImpressumLinks seperate />
  </SubFooterDiv>
);

export default SubFooter;
