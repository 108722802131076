import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../../styles/variables";

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: 1fr 4fr 1fr;
    column-gap: 40px;
  }
`;

const CenteredFourSixthsGrid: React.FC = ({ children }) => (
  <GridDiv>
    <div />
    {children}
    <div />
  </GridDiv>
);

export default CenteredFourSixthsGrid;
