import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../../styles/variables";

const WrapperDiv = styled.div`
  position: relative;
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: ${spacers.container.mobile.top}px 4.53vw
    ${spacers.container.mobile.bottom}px;

  &.lessBottomSpacing {
    padding: ${spacers.container.mobile.bottom}px 4.53vw
      ${spacers.container.mobile.top}px;
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    padding: ${spacers.container.tablet.top}px 4.53vw
      ${spacers.container.tablet.bottom}px;

    &.lessBottomSpacing {
      padding: ${spacers.container.tablet.bottom}px 4.53vw
        ${spacers.container.tablet.top}px;
    }
  }
  @media screen and (min-width: ${breakpoints.desktop}px) {
    padding: ${spacers.container.desktop.top}px 4.53vw
      ${spacers.container.desktop.bottom}px;

    &.lessBottomSpacing {
      padding: ${spacers.container.desktop.bottom}px 4.53vw
        ${spacers.container.desktop.top}px;
    }
  }
`;

const Wrapper: React.FC = ({ children, lessBottomSpacing }) => (
  <WrapperDiv className={lessBottomSpacing ? "lessBottomSpacing" : ""}>
    {children}
  </WrapperDiv>
);

export default Wrapper;
